import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import LogoWhite from '../../assets/img/logo-white.svg'
import { ButtonLogo } from '../../components/buttons/button-logo'
import { CopyRight } from '../../components/copy-right'
import { api } from '../../service/api'

interface ICustomerInfoBasic {
  customer: {
    id: number
    name: string
    atSign: string
    referralLink: string
  }
  customerInfo: {
    bio: string
    docsCheck: string
    about: string
  }
  deepLink: string
}

export function SharedProfile() {
  const [customerInfo, setCustomerInfo] = useState<ICustomerInfoBasic>()

  const params: any = useParams()
  const id = params?.id
  async function handleGetCustomerInfo() {
    try {
      const response = await api.get('/customer/info/basic', {
        params: { customerId: id },
      })
      setCustomerInfo(response.data)
    } catch (err: any) {
      alert(err?.response?.data)
      // console.log('erro carai', err?.response?.data || err)
    }
  }

  function redirectToAppStore() {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
    // const isAndroid = /Android/.test(navigator.userAgent);

    const iosStoreUrl =
      'https://apps.apple.com/br/app/beev-business-network/id6476163409'
    const androidStoreUrl =
      'https://play.google.com/store/apps/details?id=com.beev.beev'

    const appUrl = String(customerInfo?.deepLink)
    const storeUrl = isIOS ? iosStoreUrl : androidStoreUrl

    const start = new Date().getTime()
    window.location.href = appUrl

    setTimeout(() => {
      const end = new Date().getTime()
      if (end - start < 2000) {
        // Redireciona para a loja de aplicativos se o deep link falhar
        window.location.href = storeUrl
      }
    }, 1000)
  }

  useEffect(() => {
    if (id) {
      handleGetCustomerInfo()
    }
  }, [id])

  useEffect(() => {
    if (customerInfo) {
      setTimeout(() => {
        redirectToAppStore()
      }, 2000)
    }
  }, [customerInfo])

  if (!customerInfo) {
    return <></>
  }

  return (
    <div className="flex-1 shared-container items-center">
      <div className="just-mobile-container items-center justify-between">
        <img alt="" className="logo" src={LogoWhite} />
        <h1>Meu perfil</h1>
        <img
          alt=""
          className="profile-img"
          src={`${process.env.REACT_APP_API_URL}files/customer/avatar?id=${customerInfo?.customer?.id}`}
        />
        <p className="name">{customerInfo?.customer?.name}</p>
        <div className="call">
          <text>
            Convido você a conhecer meu perfil no Beev. Descrubra mais sobre
            minha trajetória profissional, conquistas e interesses
          </text>
        </div>
        <ButtonLogo onClick={redirectToAppStore} />
        <CopyRight />
      </div>
    </div>
  )
}
