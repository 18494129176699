export function CopyRight() {
  return (
    <div className="copy-right">
      <p>
        {' '}
        © {new Date().getFullYear()} Beev. Todos os direitos reservados.
        Qualquer uso não autorizado, incluindo, mas não se limitando a,
        reprodução, distribuição, exibição pública ou criação de obras derivadas
        com base neste material, sem a permissão expressa por escrito da Beev, é
        estritamente proibido.
      </p>
    </div>
  )
}
