import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import LogoWhite from '../../assets/img/logo-white.svg'
import { ButtonLogo } from '../../components/buttons/button-logo'
import { CopyRight } from '../../components/copy-right'
import { api } from '../../service/api'

export function SharedEvent() {
  const [eventInfo, setEventInfo] = useState<any>()

  const params: any = useParams()
  const id = params?.id

  async function handleGetCustomerInfo() {
    try {
      const response = await api.get('/event/find-by-id/basic', {
        params: { eventId: id },
      })
      setEventInfo(response.data)
    } catch (err: any) {
      alert(err?.response?.data)
    }
  }

  function redirectToAppStore() {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
    const iosStoreUrl =
      'https://apps.apple.com/br/app/beev-business-network/id6476163409'
    const androidStoreUrl =
      'https://play.google.com/store/apps/details?id=com.beev.beev'

    const appUrl = String(eventInfo?.deepLink)
    const storeUrl = isIOS ? iosStoreUrl : androidStoreUrl

    const start = new Date().getTime()
    window.location.assign(appUrl)

    setTimeout(() => {
      const end = new Date().getTime()
      if (end - start < 3000) {
        // Redireciona para a loja de aplicativos se o deep link falhar
        window.location.href = storeUrl
      }
    }, 5000) // Ajuste o tempo de espera conforme necessário
  }

  useEffect(() => {
    if (id) {
      handleGetCustomerInfo()
    }
  }, [id])

  useEffect(() => {
    if (eventInfo) {
      redirectToAppStore()
    }
  }, [eventInfo])

  if (!eventInfo) {
    return <></>
  }

  return (
    <div className="flex-1 shared-container items-center">
      <div className="just-mobile-container items-center justify-between">
        <img alt="" className="logo" src={LogoWhite} />
        <img
          alt=""
          className="profile-img"
          src={`${eventInfo?.photos?.thumb?.url}`}
        />
        <p className="name">{eventInfo?.name}</p>
        <div className="call">
          <text>{eventInfo?.descriptionShort}</text>
        </div>
        <ButtonLogo onClick={redirectToAppStore} />
        <CopyRight />
      </div>
    </div>
  )
}
