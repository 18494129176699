// import { useContext, useState } from "react";
// import { Home } from "../pages/public/Home";
import { PublicRoutes } from './PublicRoutes'

export default function Routes() {
  return (
    // !admin.id ? <Login /> : <AuthRoutes />
    // <Teste />
    <PublicRoutes />
  )
}
