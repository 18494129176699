import axios from 'axios'

let isRefreshing = false
const failedRequestsQueue: Array<any> = []

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

api.interceptors.request.use(
  (config: any) => {
    config.headers['app-lang'] = 'pt-br'
    if (sessionStorage.getItem('token-id')) {
      config.headers['token-id'] = sessionStorage.getItem('token-id')
    }
    if (sessionStorage.getItem('token-csrf')) {
      config.headers['token-csrf'] = sessionStorage.getItem('token-csrf')
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

api.interceptors.response.use(
  (response) => {
    if (response.headers && response.headers['gear-io-csrf']) {
      sessionStorage.setItem('gear-io-csrf', response.headers['gear-io-csrf'])
    }
    if (response.data.user) {
      const user = response.data.user
      if (user === Object(user)) {
        sessionStorage.setItem('user', JSON.stringify(response.data.user))
      }
    }
    return response
  },
  (error) => {
    if (error?.response?.headers && error?.response.headers['token-csrf']) {
      sessionStorage.setItem(
        'token-csrf',
        error?.response?.headers['token-csrf'],
      )
    }
    if ((error?.response?.data?.errorCode || '').match(/^CSRF/g)) {
      const originalConfig = error.config
      // sessionStorage.removeItem("user");

      if (!isRefreshing) {
        isRefreshing = true
        // api.get("/session")
        // 	.then((response) => {
        // 		sessionStorage.setItem("token-id", response.data.id);
        // 		sessionStorage.setItem("token-csrf", response.data.csrf);

        // 		failedRequestsQueue.forEach((request: any) =>
        // 			request.success({
        // 				id: sessionStorage.getItem("token-id"),
        // 				csrf: sessionStorage.getItem("token-csrf"),
        // 			})
        // 		);
        // 		failedRequestsQueue = [];
        // 	})
        // 	.catch((err) => {
        // 		failedRequestsQueue.forEach((request) => request.failure(err));
        // 		failedRequestsQueue = [];
        // 	})
        // 	.finally(() => {
        // 		isRefreshing = false;
        // 	});
      }

      return new Promise((resolve, reject) => {
        failedRequestsQueue.push({
          success: (session: any) => {
            originalConfig.headers['token-id'] = session.id
            originalConfig.headers['token-csrf'] = session.csrf

            resolve(api(originalConfig))
          },
          failure: (err: any) => {
            reject(err)
          },
        })
      })
    } /* else if (error?.response?.status !== 404 && error?.response?.status !== 500) {
    return Promise.reject(error);
  } */
    return Promise.reject(error)
  },
)
