import { BrowserRouter } from 'react-router-dom'
import './assets/fonts/fonts.css'
import './assets/main.css'
import './assets/reset.css'
import Routes from './routes'

function App() {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  )
}

export default App
