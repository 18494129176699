import { Route, Routes } from 'react-router-dom'
import { SharedEvent } from '../pages/public/SharedEvent'
import { SharedProfile } from '../pages/public/SharedProfile'

export function PublicRoutes() {
  return (
    <Routes>
      <Route path="/" element={<SharedProfile />} />
      <Route path="/shared/profile/:id" element={<SharedProfile />} />
      <Route path="/shared/event/:id" element={<SharedEvent />} />
    </Routes>
  )
}
