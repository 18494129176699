// import LogoWhite from '../../../assets/img/logo-white.svg'

interface IProps {
  onClick: () => void
}

export function ButtonLogo({ onClick }: IProps) {
  return (
    <button className="bt btn-logo" onClick={onClick}>
      <svg
        width="60"
        height="28"
        viewBox="0 0 91 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M88.6987 6.95361V5.367H89.4956C89.5595 5.367 89.6291 5.38563 89.7044 5.4229C89.7797 5.45873 89.8436 5.51463 89.8962 5.59059C89.9502 5.66512 89.9772 5.75971 89.9772 5.87437C89.9772 5.99047 89.9495 6.08936 89.8941 6.17106C89.8387 6.25132 89.7712 6.31223 89.6916 6.3538C89.6135 6.39536 89.5396 6.41614 89.47 6.41614H88.8947V6.15816H89.3635C89.4104 6.15816 89.4608 6.13451 89.5148 6.08721C89.5702 6.03991 89.5979 5.96897 89.5979 5.87437C89.5979 5.77691 89.5702 5.71098 89.5148 5.67658C89.4608 5.64219 89.4132 5.62499 89.372 5.62499H89.0439V6.95361H88.6987ZM89.649 6.20545L90.0411 6.95361H89.6618L89.2783 6.20545H89.649ZM89.2953 7.89956C89.0595 7.89956 88.8386 7.85513 88.6326 7.76626C88.4266 7.6774 88.2455 7.55414 88.0892 7.39649C87.933 7.23883 87.8108 7.05609 87.7227 6.84827C87.6346 6.64045 87.5906 6.41758 87.5906 6.17966C87.5906 5.94174 87.6346 5.71886 87.7227 5.51104C87.8108 5.30322 87.933 5.12048 88.0892 4.96282C88.2455 4.80517 88.4266 4.68191 88.6326 4.59305C88.8386 4.50418 89.0595 4.45975 89.2953 4.45975C89.5311 4.45975 89.752 4.50418 89.958 4.59305C90.164 4.68191 90.3451 4.80517 90.5014 4.96282C90.6576 5.12048 90.7798 5.30322 90.8679 5.51104C90.956 5.71886 91 5.94174 91 6.17966C91 6.41758 90.956 6.64045 90.8679 6.84827C90.7798 7.05609 90.6576 7.23883 90.5014 7.39649C90.3451 7.55414 90.164 7.6774 89.958 7.76626C89.752 7.85513 89.5311 7.89956 89.2953 7.89956ZM89.2953 7.48678C89.534 7.48678 89.7513 7.42802 89.9473 7.31049C90.1434 7.19296 90.2997 7.03531 90.4161 6.83752C90.5326 6.63973 90.5909 6.42044 90.5909 6.17966C90.5909 5.93887 90.5326 5.71958 90.4161 5.52179C90.2997 5.324 90.1434 5.16635 89.9473 5.04882C89.7513 4.93129 89.534 4.87253 89.2953 4.87253C89.0566 4.87253 88.8393 4.93129 88.6433 5.04882C88.4472 5.16635 88.2909 5.324 88.1745 5.52179C88.058 5.71958 87.9997 5.93887 87.9997 6.17966C87.9997 6.42044 88.058 6.63973 88.1745 6.83752C88.2909 7.03531 88.4472 7.19296 88.6433 7.31049C88.8393 7.42802 89.0566 7.48678 89.2953 7.48678Z"
          fill="white"
        />
        <path
          d="M35.2594 22.7151V5.10328H41.9419C43.2033 5.10328 44.2517 5.30394 45.087 5.70525C45.928 6.10083 46.5559 6.6426 46.9707 7.33056C47.3912 8.01852 47.6015 8.79821 47.6015 9.66962C47.6015 10.3862 47.4651 10.9997 47.1923 11.5099C46.9196 12.0144 46.5531 12.4243 46.0928 12.7396C45.6325 13.055 45.1183 13.2814 44.5501 13.419V13.591C45.1694 13.6254 45.7632 13.8175 46.3315 14.1672C46.9054 14.5111 47.3742 14.9985 47.7379 15.6291C48.1015 16.2597 48.2834 17.0222 48.2834 17.9166C48.2834 18.8281 48.0646 19.6479 47.627 20.376C47.1895 21.0984 46.5304 21.6688 45.6496 22.0873C44.7688 22.5058 43.6608 22.7151 42.3254 22.7151H35.2594ZM38.4217 20.0492H41.8225C42.9704 20.0492 43.7972 19.8285 44.3029 19.3871C44.8143 18.9399 45.07 18.3666 45.07 17.6672C45.07 17.1455 44.9421 16.6754 44.6864 16.2568C44.4307 15.8326 44.0671 15.5001 43.5954 15.2593C43.1238 15.0128 42.5612 14.8895 41.9078 14.8895H38.4217V20.0492ZM38.4217 12.5935H41.5498C42.0953 12.5935 42.5868 12.4931 43.0244 12.2925C43.4619 12.0861 43.8057 11.7966 44.0557 11.4239C44.3114 11.0455 44.4393 10.5984 44.4393 10.0824C44.4393 9.40017 44.2006 8.83834 43.7233 8.39689C43.2516 7.95545 42.5499 7.73473 41.618 7.73473H38.4217V12.5935Z"
          fill="white"
        />
        <path
          d="M55.4938 22.9731C54.1812 22.9731 53.0476 22.6979 52.0929 22.1475C51.144 21.5914 50.4138 20.806 49.9024 19.7912C49.391 18.7708 49.1353 17.5697 49.1353 16.1881C49.1353 14.8293 49.391 13.6369 49.9024 12.6107C50.4195 11.5787 51.1411 10.7761 52.0674 10.2028C52.9936 9.62376 54.0818 9.33424 55.3319 9.33424C56.1388 9.33424 56.9002 9.4661 57.6162 9.72982C58.3378 9.9878 58.9742 10.3891 59.5254 10.9338C60.0823 11.4784 60.5198 12.1721 60.8381 13.0148C61.1563 13.8519 61.3154 14.8494 61.3154 16.0075V16.962H50.5843V14.8637H58.3577C58.352 14.2675 58.2242 13.7372 57.9742 13.2728C57.7241 12.8027 57.3747 12.4329 56.9258 12.1635C56.4825 11.894 55.9655 11.7593 55.3745 11.7593C54.7437 11.7593 54.1897 11.9141 53.7124 12.2237C53.2351 12.5275 52.8629 12.9288 52.5958 13.4276C52.3344 13.9206 52.2009 14.4624 52.1952 15.0529V16.8846C52.1952 17.6528 52.3344 18.3121 52.6129 18.8625C52.8913 19.4071 53.2805 19.8256 53.7806 20.118C54.2806 20.4047 54.8659 20.548 55.5364 20.548C55.9853 20.548 56.3916 20.4849 56.7553 20.3588C57.119 20.227 57.4343 20.0349 57.7014 19.7826C57.9685 19.5304 58.1702 19.2179 58.3066 18.8453L61.1875 19.1721C61.0057 19.9403 60.6591 20.6111 60.1476 21.1844C59.6419 21.7519 58.9941 22.1934 58.2043 22.5087C57.4144 22.8183 56.511 22.9731 55.4938 22.9731Z"
          fill="white"
        />
        <path
          d="M68.5216 22.9731C67.2089 22.9731 66.0753 22.6979 65.1207 22.1475C64.1717 21.5914 63.4416 20.806 62.9301 19.7912C62.4187 18.7708 62.163 17.5697 62.163 16.1881C62.163 14.8293 62.4187 13.6369 62.9301 12.6107C63.4472 11.5787 64.1689 10.7761 65.0951 10.2028C66.0213 9.62376 67.1095 9.33424 68.3596 9.33424C69.1665 9.33424 69.9279 9.4661 70.6439 9.72982C71.3656 9.9878 72.002 10.3891 72.5532 10.9338C73.11 11.4784 73.5476 12.1721 73.8658 13.0148C74.184 13.8519 74.3431 14.8494 74.3431 16.0075V16.962H63.612V14.8637H71.3855C71.3798 14.2675 71.2519 13.7372 71.0019 13.2728C70.7519 12.8027 70.4024 12.4329 69.9535 12.1635C69.5103 11.894 68.9932 11.7593 68.4022 11.7593C67.7715 11.7593 67.2175 11.9141 66.7402 12.2237C66.2628 12.5275 65.8906 12.9288 65.6236 13.4276C65.3622 13.9206 65.2287 14.4624 65.223 15.0529V16.8846C65.223 17.6528 65.3622 18.3121 65.6406 18.8625C65.919 19.4071 66.3083 19.8256 66.8083 20.118C67.3084 20.4047 67.8937 20.548 68.5642 20.548C69.0131 20.548 69.4194 20.4849 69.7831 20.3588C70.1467 20.227 70.4621 20.0349 70.7292 19.7826C70.9962 19.5304 71.1979 19.2179 71.3343 18.8453L74.2153 19.1721C74.0334 19.9403 73.6868 20.6111 73.1754 21.1844C72.6697 21.7519 72.0219 22.1934 71.232 22.5087C70.4422 22.8183 69.5387 22.9731 68.5216 22.9731Z"
          fill="white"
        />
        <path
          d="M87.0725 9.50623L82.4102 22.7151H79.0008L74.3384 9.50623H77.6285L80.6373 19.3183H80.7737L83.791 9.50623H87.0725Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.985 14C27.985 6.26801 21.7204 3.37976e-07 13.9925 0C6.26467 -3.37976e-07 9.49428e-07 6.26801 6.11633e-07 14L0 28H27.985V14ZM24.6192 14.5805C25.0066 14.3619 25.0066 13.8037 24.6192 13.585L22.4098 12.3382C19.5837 10.7434 17.2473 8.40571 15.6533 5.5781L14.4072 3.36757C14.1887 2.97996 13.6308 2.97996 13.4123 3.36757L12.1661 5.5781C10.5721 8.40571 8.23572 10.7434 5.40961 12.3382L3.20026 13.585C2.81286 13.8037 2.81286 14.3619 3.20026 14.5805L5.40961 15.8273C8.23572 17.4221 10.5721 19.7598 12.1661 22.5874L13.4123 24.798C13.6308 25.1856 14.1887 25.1856 14.4072 24.798L15.6533 22.5874C17.2473 19.7598 19.5837 17.4221 22.4098 15.8273L24.6192 14.5805Z"
          fill="white"
        />
        <path
          d="M29.7576 27.6633V26.8896H30.0542C30.0962 26.8896 30.1341 26.9001 30.168 26.9211C30.2019 26.9421 30.2287 26.9703 30.2486 27.0057C30.2685 27.0407 30.2784 27.0795 30.2784 27.1223C30.2784 27.1573 30.272 27.1895 30.2591 27.219C30.2466 27.2481 30.2289 27.2733 30.2061 27.2947C30.1836 27.3161 30.1577 27.3323 30.1282 27.3433L30.3132 27.6633H30.158L29.9791 27.3555H29.8924V27.6633H29.7576ZM29.8924 27.2206H30.0388C30.0579 27.2206 30.0754 27.2159 30.0912 27.2063C30.1071 27.1967 30.1198 27.1838 30.1293 27.1676C30.1389 27.1514 30.1437 27.1333 30.1437 27.1134C30.1437 27.0932 30.1389 27.0749 30.1293 27.0587C30.1198 27.0425 30.1071 27.0296 30.0912 27.0201C30.0754 27.0105 30.0579 27.0057 30.0388 27.0057H29.8924V27.2206Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.0279 27.8446C30.3425 27.8446 30.5975 27.5895 30.5975 27.2747C30.5975 26.96 30.3425 26.7049 30.0279 26.7049C29.7134 26.7049 29.4584 26.96 29.4584 27.2747C29.4584 27.5895 29.7134 27.8446 30.0279 27.8446ZM30.0279 28C30.4283 28 30.7528 27.6753 30.7528 27.2747C30.7528 26.8742 30.4283 26.5494 30.0279 26.5494C29.6276 26.5494 29.303 26.8742 29.303 27.2747C29.303 27.6753 29.6276 28 30.0279 28Z"
          fill="white"
        />
      </svg>
      Abrir no App
    </button>
  )
}
